<!--
 * @Author: sqk
 * @Date: 2020-09-02 10:28:52
 * @LastEditTime: 2020-09-28 21:04:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\filtrate\Filtrate.vue
-->

<template>
  <div class="sort-bar-box clearfix">
    <ul>
      <li
        @click="sortFun(item, index)"
        v-for="(item, index) in data"
        :key="index"
        class="d-f-c"
        :class="
          item.sortStatus == 0 ? '' : item.sortStatus == 1 ? 'sort' : 'resort'
        "
      >
        <span>{{ item.name }}</span>
        <i class="iconfont icon-shangsheng"></i>
      </li>
      <!-- <li class="d-f-c">
        <span>销量</span>
        <i class="iconfont icon-shangsheng"></i>
      </li>
      <li class="d-f-c">
        <span>价格</span>
        <i class="iconfont icon-shangsheng"></i>
      </li> -->
    </ul>
    <div  class="total-box">共搜索到 {{total}} 条消息
      <span v-if="pending">待处理 {{pending}} 条</span>
      <span v-if="notRead && type === 'notice'" :class="clickFlag?'readAll readAllActive':'readAll'" @click="readAll">清除未读 </span>
      <span v-if=" !refesh && type !== 'notice'" class="readAll" @click="readAll">全部设为已读 </span>
      <span v-if="refesh && type != 'notice'" class="readAll" @click="refeshTodoList">刷新 </span>
    </div>
    <div class="input-search" >
      <a-input
        :style="'width:' + searchWidth + 'px'"
        :placeholder="searchPlace"
        v-model="searchValue"
        @search="onSearch"
        @keydown.enter="onSearch"
        @change="change"
      >
         <i class="iconfont icon-shouye-sousuo" slot="suffix"  @click="onSearch"/>
      </a-input>
    </div>
    
    <div class="edit">
      <slot />
    </div>
    <!-- <div class="go-box">
      <span class="label">快速切换至</span>
      <a-select v-model="currentProvide" placeholder="两个供应商可选" class="sel-wrap">
        <a-select-option v-for="item in provides" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SortBar",
  data() {
    return {
      currentProvide:undefined,
      searchValue:'',
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
    },
     pending: {
      type: [String, Number],
    },
    notRead : {
      type: Boolean,
      default: false
    },
    refesh : {
       type: [String, Number],
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    provides: {
      //供应商列表
      type: Array,
      default: function (){
        return []
      }
    },
    searchWidth: {
      type: Number,
      default: 270
    },
    searchPlace: {
      type: String,
      default: "请输入工程项目名称/项目编码/招标单位"
    },
    type : {
      type: String,
      default:''
    },
    clickFlag: {
      type: Boolean,
      default: false
    },
  },
  mounted() {},
  methods: {
    sortFun(item, idx) {
      //需要emit
      for (let key in this.data) {
        if (key == idx) {
          continue;
        } else {
          this.data[key].sortStatus = 0;
        }
      }
      if (item.sortStatus == 0) {
        item.sortStatus = 1;
      } else if (item.sortStatus == 1) {
        item.sortStatus = 2;
      } else {
        item.sortStatus = 0;
      }

      this.$emit('click',item);

    },
    onSearch() {
      this.$emit('search',this.searchValue);
    },
    change() {
       this.$emit('change',this.searchValue);
    },
    // 点击全部已读触发
    readAll () {
      this.$emit('readAll');
      // readOnly ( { priceMessageId:null } ) .then(res=>{
      //   this.getNoticeList()
      // }).catch(error=>{
      //   console.log(error)
      // })
    },
    refeshTodoList () {
      this.$emit('refeshTodoList',this.searchValue);
    }
  }
};
</script>

<style lang="less" scoped>
.sort-bar-box {
  width: 1188px;
  height: 54px;
  margin: 0 auto;
  background-color: #fff;
  // padding-left: 10px;
  ul {
    float: left;
    padding-right: 28px;
    color:#262626!important;
    li {
      height: 54px;
      line-height: 54px;
      text-align: center;
      padding: 0 6px;
      margin-left: 20px;
      float: left;
      cursor: pointer;
      transition: all 0.1s;
      &.sort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          transform: rotate(180deg);
          color: #00aaa6;
          
        }
      }
      &.resort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          color: #00aaa6;
        }
      }
      &:hover {
        color: #00aaa6;
        .iconfont {
          color: #00aaa6;
        }
      }
      span {
        display: inline;
      }
      .iconfont {
        font-size: 8px;
        margin-left: 6px;
        color: #777;
        visibility: hidden;
        
      }
     
    }
  }
  .total-box {
    font-size: 14px;
    color: #777777;
    height: 18px;
    line-height: 18px;
    border-left: 1px solid #eee;
    text-indent: 23px;
    float: left;
    margin-top: 18px;
  }
  .input-search {
    float: right;
    padding: 11px 24px 11px 0;
    .iconfont {
      color: #aaa;
    }
    .iconfont:hover {
      color: #00aaa6;
      cursor: pointer;
    }
  }
  .go-box {
    float: right;
    padding: 11px 10px 11px 0;
    .title {
      color: #262626;
      font-size: 16px;
    }

    .label {
      color: #262626;
      font-size: 14px;
      margin-left: 60px;
    }

    .sel-wrap {
      width: 300px;
      margin-left: 16px;
      margin-right: 40px;
    }
  }
}

.edit{
  float: right;
}
.readAll {
  float: right;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  text-align: left;
  // margin-left: 24px;
  display: block;
  cursor: pointer;
}
.readAllActive{
  color: #AAA;
}
</style>
