import { mapState } from "vuex";
import { publicPath } from "@/common/constant.js";
import {
  batchRead,
  getMessageInfo,
  getPhoneNum,
  queryNoticeList,
  readOnly,
  toOrderDetail,
  toPricepage
} from "./api";
import util from "./../noticeList/components/until";
// import sortBar from "./components/SortBar";
import sortBar from "./../todoList/components/SortBar";
import { sendMessageA } from "@/views/userOperations/IntentionList/api";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";
import previewModal from "./components/previewModal"
export default {
  components: {
    sortBar,
    sendMessage,
    previewModal
  },
  name: "index",
  data() {
    return {
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/noticeList",
          name: "noticeList",
          title: "通知",
        },
      ],
      windowList: [],
      sortList: [],
      noticeList: [],
      filterFrom: {
        pageNo: 1,
        // currentPage:1,
        status: 0,
        type: "",
        content: "",
        pageSize: 20,
        sortType: "desc",
        titleId:''
      },
      isSearch: true,
      total: "", //搜索到几条数据
      pending: "", //待处理
      totalPages: "", //数据总条数
      currentPage: "", //当前页数
      visible:false,
      messageType:'message',
      cherkMessageItem:{},
      PhoneNum:'',
      visiblePhone:false,
      orgOcode:'',
      refesh: false,
      showTips:false,
      showTipsgj:false,
      showChecked:false,//通知前面的多选框的展示
      clickFlag:false,//清除未读样式标识
      allChecked:false,
      isFixed:false,
      notRead:false
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  watch:{
    filterFrom(val){
      if((val.titleId == '57' && val.type == "101007")){
        this.showTipsgj = true
        this.showTips = false
        return
      }
      //|| (val.titleId == '58' && val.type == "101007")
      if((val.titleId == '60' && val.type == "101007") ) {
        this.refesh = true
        this.showTipsgj = false
        this.showTips = true
        return
      } else {
        this.refesh = false
        this.showTipsgj = false
        this.showTips = false
      }

      if(val.status == 1){
        this.notRead = false
        this.cancelOpeator()
      } else {
        this.notRead = true
      }

    }
  },
  created() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode: this.filterConfigStr ? this.filterConfigStr : '' };
    this.sortList = util.sortList;
    // this.getMessageFilterList();
    // this.getNoticeList(this.filterFrom);
  },
  mounted() {
    window.addEventListener("scroll", this.throttle(this.handleScroll, 100), true);
  },
    methods: {
      // 更改搜索框内容
      changeSearchVal(searchStr) {
        this.filterFrom = { ...this.filterFrom, content:searchStr, pageNo: 1 };
      },
      gotoPage(item,type,replay){
      readOnly({ priceMessageId: item.id })
        .then((res) => {
          this.getNoticeList();
        })
        .catch((error) => {
          console.log(error)
        });
      const {id} = JSON.parse(item.sourceUrl)
        this.$router.push({
          path: "/center/consultNewPage",
          query:{
            id:id,
            sourceType:'noticeList',
            type:type,
            replay:replay?replay:'',
          }
        });

      return

    },
    // 打开短信弹窗
    Pushmessage(item){
      this.orgOcode = ''
      this.cherkMessageItem = item
      let data = {
        custId:item.custId,
        account:item.account,
        orderCode:item.orderCode+'@1'
      }
      getPhoneNum(data).then(res=>{
        if(res.data.code == 0) {
          this.PhoneNum = res.data.data.userPhone
        } else {
          this.PhoneNum = ''
        }
      })

      // 如果ordercode是dmp的 展示预览的弹窗
      if(item.orderCode && item.orderCode.indexOf('dmp')!==-1) {
        this.orgOcode = item.orderCode
        this.visiblePhone = true
      } else {
        // 其余的展示编辑短息d
        this.visible = true
      }
    },
    // 发短信
  async  confirmOk(data){
      var userId = null
      if( this.cherkMessageItem.orderCode && this.cherkMessageItem.orderCode.indexOf('user') !== -1){
        userId = this.cherkMessageItem.orderCode.split('@')[1]
        } else {
          userId = null
        }
      let dataA  = {
        list:[
          {
            "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo": [this.PhoneNum],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
            "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
            "userId":userId,//":"用户id"
            "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
          }
        ]
      }
     await sendMessageA(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('发送成功',3)
          this.visible = false
          // 把状态改为已读
          readOnly({ priceMessageId: this.cherkMessageItem.id })
            .then((res) => {
              this.getNoticeList();
            })
            .catch((error) => {
              console.log(error);
            });
        } else if(res.data.code !== 400) {
          this.$message.warning(res.data.msg,3)
          this.visible = false
        } else {
          this.visible = false
        }
      })
    },
    isJson(str) {
      try {
        JSON.parse(str);
        return true
      } catch (e) {
        return false
      }
    },
    //获取商品列表
    async getNoticeList() {
      this.pageLoadFlag = true;
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1,
      };
      try {
        const res = await queryNoticeList(this.filterFrom);
        // 渲染出来的数据列表
        this.noticeList = res.data.priceMessageList;
        this.noticeList.forEach(item=>{
          item.navigateFlag = item.sourceUrl && this.isJson(item.sourceUrl) && JSON.parse(item.sourceUrl) && JSON.parse(item.sourceUrl).navigate == true ? false : true;
          item.checked=false
          if (item.typeCode == '15402738450'){
            item.xtwStatus=''
            item.xtwStatus = item.sourceUrl?JSON.parse(item.sourceUrl).xtwStatus:''
            item.contentT = item.sourceUrl?JSON.parse(item.sourceUrl).code:''
          }
         })
        // 查询出来的总数量
        this.total = res.data.totalCount
        // 未读的数量
        this.pending = res.data.unreadMessageNum;
        // 重新赋值页数
        this.totalPages = res.data.totalPages;
        this.currentPage = res.data.currentPage;
        this.pageLoadFlag = false;
      } catch (error) {
        console.log(error);
      }
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], pageNo: 1 };
      this.noticeList = [];
      this.getNoticeList();
      this.isSearch = false;
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        orderBy: sortStatus == 0 ? "" : sortStatus == 1 ? "desc" : "asc",
      };
      this.getNoticeList();
    },
    onSearch(content) {
      this.noticeList = [];
      this.filterFrom = { ...this.filterFrom, content };
      this.getNoticeList();
      this.isSearch = false;
    },
    loadMore() {
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: this.filterFrom.pageNo + 1,
      };
      this.pageLoadFlag = true;
      queryNoticeList(this.filterFrom)
        .then((res) => {
          // 点击加载更多 拼接数组
          this.noticeList = this.noticeList.concat(res.data.priceMessageList);
          this.noticeList.forEach(item=>{
            item.navigateFlag = item.sourceUrl && this.isJson(item.sourceUrl) && JSON.parse(item.sourceUrl) && JSON.parse(item.sourceUrl).navigate == true ? false : true;
            item.checked=false
            if (item.typeCode == '15402738450'){
              item.xtwStatus=''
              item.xtwStatus = item.sourceUrl?JSON.parse(item.sourceUrl).xtwStatus:''
              item.contentT = item.sourceUrl?JSON.parse(item.sourceUrl).code:''
            }})
          this.pageLoadFlag = false;
          this.totalPages = res.data.totalPages;
          this.currentPage = res.data.currentPage;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 点击单个标记已读
    readFlag(item) {
      if(item.sourceUrl && this.isJson(item.sourceUrl) && JSON.parse(item.sourceUrl) && JSON.parse(item.sourceUrl).navigate == true){
        return;
      }
      // 意向用户跟进、回访跳转
      if(item.typeCode == '14170774249' || item.typeCode == '14170774250'){
        let type =  item.orderCode?item.orderCode.split('@')[0]:''
        let id =  item.orderCode?item.orderCode.split('@')[1]:''
        if(type == 'user') {
          readOnly({ priceMessageId: item.id })
            .then((res) => {
              this.getNoticeList();
            })
            .catch((error) => {
              console.log(error);
            });
          let routeUrl = this.$router.resolve({
            path: "/IntentionDetail",
            query: {
              id:id,
            }
          });
          window.open(routeUrl.href, "_blank");
          return;
        }

      }
      if((this.filterFrom.titleId == '60' && this.filterFrom.type == "101007") ||
        (this.filterFrom.titleId == '57' && this.filterFrom.type == "101007")||
        (this.filterFrom.titleId == '58' && this.filterFrom.type == "101007")){
        return;
      }
      // 代理商驳回
      if(item.typeCode == '15420008629'){
        const {id} = item.sourceUrl?JSON.parse(item.sourceUrl):''
        readOnly({ priceMessageId: item.id })
          .then((res) => {
            this.getNoticeList();
          })
          .catch((error) => {
            console.log(error);
          });
        // 要跳转编辑页面
        let routeUrl = this.$router.resolve({
          path: "/agentVerificationActivities/addOredit",
          query: {
            id:id,
            type:'edit'
          }
        });
        window.open(routeUrl.href, "_blank");
        return;

      }

        // 后端返回带# 是跳转系统内的链接  替换掉需要的参数
        // 未带# 跳转系统外链接 拼接地址跳转
      // 如果返回none 不能跳转任何操作
      if(item.targetUrl == 'none') {
        // 把状态改为已读
        readOnly({ priceMessageId: item.id })
          .then((res) => {
            this.getNoticeList();
          })
          .catch((error) => {
            console.log(error);
          });
        return
      }
      if( item.typeCode != 15402738450){
        if(item.targetUrl.indexOf('#') !== -1 ) {
          // 替换掉接口返回的变量
          item.targetUrl = item.targetUrl.replace('${content}',item.content)
          item.targetUrl = item.targetUrl.replace('${sourceId}',item.sourceId)
          let routeUrl = this.$router.resolve({
            path: item.targetUrl.split('#')[1],// 截取#后的链接进行跳转
          });
          window.open(routeUrl.href, "_blank");
        } else {
          const BASE_API = publicPath;
          let url = BASE_API + item.targetUrl
          window.open(url, "_blank");
        }
      }

      // 把状态改为已读
      readOnly({ priceMessageId: item.id })
      .then((res) => {
        this.getNoticeList();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    // 滚动
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    // 滚动页面
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    //   清除未读底部按钮
    clearNoread(){
      let list =  this.noticeList.filter(item=>item.checked)
      if(list.length<=0){
        this.$message.warning('请至少勾选一项通知')
        return
      }
      let data = list.map(it=>it.id)
      batchRead({priceMessageIds:data}).then(res=>{
        if(res.data.code == 0){
          this.$message.success('清除未读成功')
          this.clickFlag = false
          this.getNoticeList()
          this.cancelOpeator()
        }else{
          this.$message.warning(res.data.msg)
          this.clickFlag = false
          this.cancelOpeator()

        }
      })

    },
    // 全选按钮更改
    allCherkChange(e){
      this.allChecked = e.target.checked
      this.$forceUpdate()
      if(this.allChecked){
        this.noticeList.forEach(item=>{
          item.checked = true
        })
      } else{
        this.noticeList.forEach(item=>{
          item.checked = false
        })
      }
    },
    // 多选框的修改
    checkedChange(item,e){
      item.checked=e.target.checked
      this.$forceUpdate()
      let num = this.noticeList.filter(it=>it.checked)
      if(num.length>=this.noticeList.length){
        this.allChecked = true
      } else{
        this.allChecked = false
      }
    },
    //  关闭消除未读的按钮
    cancelOpeator(){
      this.allChecked = false
      this.showChecked = false
      this.clickFlag = false
      this.noticeList.forEach(item=>{
        item.checked = false
      })
    },
    // 清除未读
    readAll() {
      this.clickFlag = true
      this.showChecked = true
      // readOnly({ priceMessageId: null, type: this.filterFrom.type })
      //   .then((res) => {
      //     this.getNoticeList();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    // 点击审核
    shenhe(id) {
        let routeUrl = this.$router.resolve({
            path: '/channelOrder/orderExamine',
            query: {
                id
            }
        });
        window.open(routeUrl.href, "_blank");
    },
  },
};
