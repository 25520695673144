import http from "@/utils/request";
export function messageFilterList(data) {
    return http({
      method: "get",
      url: "/priceMessage/messageList.nd",
      data
    });
  }
  export function queryNoticeList(data) {
    return http({
      method: "post",
      url: "/priceMessage/list.nd",
      data
    });
  }
//  全部设为已读  /priceMessage/read.nd?priceMessageId=
// 单个标记已读  /priceMessage/read.nd?priceMessageId=86718

export function readOnly(data) {
  return http({
    method: "get",
    url: "/priceMessage/read.nd",
    params:data
  });
}
// 跳转订单页面 order/orderLine.htm
export function toOrderDetail(data) {
  return http({
    method: "get",
    url: "/order/orderLine.htm",
    params:data
  });
}
// 跳转价格页面 channelPriceProduct/priceMsgDetail.htm?sourceId=32020101500441
export function toPricepage(data) {
  return http({
    method: "get",
    url: "channelPriceProduct/priceMsgDetail.htm",
    params:data
  });
}

// 促销资源兑现列表
export function agentActivityApplyNotice(data) {
  return http({
    method: "get",
    url: "/fast/userReport/agentActivityApplyNotice.nd",
    params:data
  });
}
export function getPhoneNum(data) {
  return http({
    method: "post",
    url: "/crmShopPotentialUser/findPhone.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 获取短信内容
export function getMessageInfo(data) {
  return http({
    method: "get",
    url: "/delayMsgTask/userActiveSendShortMsg/"+data+'.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}

// 批量已读

export function batchRead(data) {
  return http({
    method: "POST",
    url: "/priceMessage/batchRead.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data:data
  });
}