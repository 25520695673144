<template>
  <a-modal
    class="ReadModalPhone"
    v-model="visible"
    :width="352"
    :keyboard="false"
    :closable="false"
    :footer="null"
    :maskClosable="false"
  >
    <div class="contentBox">
      <!--      <img src="@/assets/home/编组 53.png">-->
      <div class="timeBox">
        {{ time }}
      </div>
      <div class="readInfo">
       <span>{{message}}</span>
      </div>
      <div class="btnGroup" @click="handleOk">
        确认发送
      </div>
    </div>
    <div class="iconClose">
      <img @click="closeX" src="@/assets/home/编组 16.png" />
    </div>
  </a-modal>
</template>
<script>
import { getMessageInfo } from "@/views/center/noticeList/api";

export default {
  name: "preview-modal",
  data() {
    return {
      message:'',
      time:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgOcode:{
      type: String,
      default: ''
    }
  },
  watch:{
    visible(val,old){
      if(val) {
        this.getMessage()
        this.getTime()
      }
    }
  },
  mounted() {

  },
  methods: {
    // 获取当前时间
    getTime() {
      var d = new Date();
      d.getHours()
      d.getMinutes()
      this.time= d.getHours()+':'+d.getMinutes()
    },
    // 获取短信内容
    getMessage(){
      getMessageInfo(this.orgOcode).then(res=>{
        this.message = res.data.data.massageContent
      })
    },
    // 关闭弹窗
    closeX() {
      this.$emit('update:visible',false)
    },
    // 确定发送
    handleOk(){
      let data = {
        messageInfo:this.message,
      };
      this.$emit('update:visible',false)
      this.$emit('handleOk',data)
    },

  }
};
</script>

<style lang="less" scoped>
.ReadModalPhone {
  /deep/.ant-modal {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    top: 100px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none;
    background: none;
    box-shadow: none;
    margin-top: 110px;
  }
  /deep/.ant-modal-content {
    position: relative;
    //background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    background: none;
    box-shadow: none;
    .contentBox {
      //background:#fff;
      width: 304px;
      height: 372px;
      background-image: url("https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/%E6%89%8B%E6%9C%BA%E8%83%8C%E6%99%AF.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=3724506167&Signature=cJn0MUUp7zj55Gcvw0WlD4oxR78%3D");
      background-size: 100% 100%;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .timeBox {
        font-size: 36px;
        font-weight: 400;
        color: #262626;
        line-height: 50px;
        margin-bottom: 8px;
        margin-top: 38px;
      }
      .readInfo {
        width: 241px;
        height: 170px;
        background-image: url("../../../../assets/noticeList/方块.png");
        background-size: 100% 100%;
        padding: 10px 9px 15px 12px;
        span{
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        }



      }
      .btnGroup {
        cursor: pointer;
        width: 188px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 24px;
        margin-top: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00AAA6;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .iconClose {
      text-align: center;
      margin-top: 17px;
      width: 304px !important;
      cursor: pointer;
      img {
        width: 46px;
        height: 46px;
      }
    }
  }
}
.ant-modal-close-x {
  display: block !important;
  width: 42px !important;
  height: 42px !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 42px !important;
  text-align: center !important;
  text-transform: none !important;
  text-rendering: auto !important;
}
.ant-modal-close-x:hover {
  background-color: #e4f8f7 !important;
  border-radius: 0px 10px 0px 0px;
  color: #08aba8 !important;
  width: 42px;
  height: 42px;
}
</style>